<script setup>
    import { computed } from 'vue';

    const props = defineProps({
        label: {
            type: String,
            required: false,
            default: null,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        icon: {
            type: String,
            required: false,
            default: null,
        },

        prependIcon: {
            type: String,
            required: false,
            default: null,
        },

        appendIcon: {
            type: String,
            required: false,
            default: null,
        },

        slim: {
            type: Boolean,
            required: false,
            default: false,
        },

        pill: {
            type: Boolean,
            required: false,
            default: false,
        },

        colour: {
            type: String,
            required: false,
            default: 'primary',
        },

        outline: {
            type: Boolean,
            required: false,
            default: false,
        },

        class: {
            type: String,
            required: false,
            default: null,
        }
    });

    const borderClasses = computed(() => {
        if (props.outline) {
            if (['primary', 'secondary', 'danger', 'warning', 'info', 'success'].includes(props.colour)) {
                switch (props.colour) {
                    case 'primary':
                        return 'border-ns-modern-green text-ns-modern-green';
                    case 'secondary':
                        return 'border-gray-700 text-gray-700 dark:border-white dark:text-white';
                    case 'danger':
                        return 'border-red-700 text-red-700';
                    case 'warning':
                        return 'border-yellow-700 text-yellow-700';
                    case 'info':
                        return 'border-blue-700 text-blue-700';
                    case 'success':
                        return 'border-green-700 text-green-700';
                    default:
                        return 'border-ns-modern-green text-ns-modern-green';
                }
            } else {
                return `border-${props.colour}-700 text-${props.colour}-700 dark:border-${props.colour}-300 dark:text-${props.colour}-300`;
            }
        }
    });

    const backgroundClasses = computed(() => {
        if (!props.outline) {
            if (['primary', 'secondary', 'danger', 'warning', 'info', 'success'].includes(props.colour)) {
                switch (props.colour) {
                    case 'primary':
                        return 'bg-ns-modern-green';
                    case 'secondary':
                        return 'bg-gray-700 dark:bg-white';
                    case 'danger':
                        return 'bg-red-700';
                    case 'warning':
                        return 'bg-yellow-700';
                    case 'info':
                        return 'bg-blue-700';
                    case 'success':
                        return 'bg-green-700';
                }
            } else {
                if (props.colour.startsWith('bg-')) {
                    return props.colour;
                }

                return `bg-${props.colour}-700 dark:bg-${props.colour}-300`;
            }
        }
    });
</script>
<template>
    <button
        :disabled="disabled"
        :class="{
            'py-3': !slim && !icon,
            'py-1': slim,
            'rounded-lg': !pill && !icon,
            'rounded-full': pill || icon,
            'h-8 w-8': icon,
            'px-6': !icon,
            [borderClasses + ' border']: props.outline,
            [backgroundClasses]: !props.outline,
            [props.class]: props.class
        }"
        class="font-medium disabled:opacity-40 text-ns-deep-blue"
        type="button"
    >
        <span v-if="prependIcon" class="mr-2">
            <i :class="prependIcon"></i>
        </span>
        <span v-if="$slots.default">
            <slot />
        </span>
        <span v-else-if="icon">
            <i :class="icon"></i>
        </span>
        <span v-else>
            {{ label }}
        </span>
        <span v-if="appendIcon" class="ml-2">
            <i :class="appendIcon"></i>
        </span>
    </button>
</template>
