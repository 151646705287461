<script setup>
    import { ref, computed } from 'vue';
    import { useContactsLists } from '@/stores/ContactsLists';
    import { useRouter, useRoute } from 'vue-router';

    const router = useRouter(),
        route = useRoute(),
        store = useContactsLists(),
        { createContactsList, selectedPeople, addSelectedPeopleToList, clearSelectedPeople } = store,
        currentStep = ref(1),
        name = ref(''),
        description = ref('');

    const isAdding = computed(() => {
        return route.query.adding === 'true' && (selectedPeople?.length ?? 0) > 0;
    });

    const nextStep = () => {
        createContactsList(name.value, description.value).then((listId) => {
            if (isAdding) {
                addSelectedPeopleToList(listId).then(() => {
                    clearSelectedPeople();
                    router.push({ name: 'contacts-list', params: { id: listId }, query: { added: 'true' }});
                });
            } else {
                currentStep.value = 2;
            }
        });
    };
</script>
<template>
    <div class="flex w-full flex-col py-4">
        <div
            v-if="!isAdding"
            class="grid grid-cols-2 gap-x-4 gap-y-2"
        >
            <div
                :class="{
                    'bg-ns-modern-green border-ns-modern-green': currentStep === 1,
                    'border-neutral-300': currentStep !== 1,
                }"
                class="rounded-full h-5 border"
                aria-hidden="true"
            ></div>
            <div
                :class="{
                    'bg-ns-modern-green border-ns-modern-green': currentStep === 2,
                    'border-neutral-300': currentStep !== 2,
                }"
                class="rounded-full h-5 border"
                aria-hidden="true"
            ></div>

            <div class="col-span-full">
                <p class="text-center text-ns-deep-blue text-sm">Step {{ currentStep }} of 2</p>
            </div>
        </div>

        <div
            v-if="currentStep === 1"
            class="flex w-full flex-col space-y-4"
        >
            <h1 class="text-center my-0">Create a New Contact List</h1>

            <ns-text-input
                v-model="name"
                handle="name"
                placeholder="Name"
            />
            <ns-textarea
                v-model="description"
                handle="description"
                placeholder="Additional Details"
            />
            <ns-button
                :label="isAdding ? 'Add' : 'Next'"
                @click="nextStep"
            />
        </div>
        <div
            v-else-if="currentStep === 2"
            class="flex w-full flex-col space-y-4"
        >
            <h1 class="text-center my-0">Add Contacts</h1>

            <div class="gradient text-center text-white rounded-lg p-6 flex flex-col space-y-4">
                <h2 class="text-xl my-0 font-semibold text-white">My Favourites</h2>
                <p class="text-white my-0">Head over to your saved contacts and add multiple people to your contacts list.</p>
                <ns-button
                    label="Select"
                    @click="router.push({ name: 'favourites' })"
                />
            </div>

            <div class="gradient text-center text-white rounded-lg p-6 flex flex-col space-y-4">
                <h2 class="text-xl my-0 font-semibold text-white">Our People</h2>
                <p class="text-white my-0">Head over to our full people search where you can search and add multiple team members to a contacts list with ease.</p>
                <ns-button
                    label="Select"
                    @click="router.push({ name: 'people-search' })"
                />
            </div>

            <div class="text-center pt-8 pb-4">
                <button
                    type="button"
                    @click="router.push({ name: 'contacts-lists' })"
                >
                    Add Later
                </button>
            </div>
        </div>
    </div>
</template>
