<script setup>
    import { onMounted, onUnmounted, ref } from 'vue';
    import { useContactsLists, storeToRefs } from '@/stores/ContactsLists';
    import { useRoute, useRouter } from 'vue-router';

    const store = useContactsLists();
    const { selectedPeople, contactsLists } = storeToRefs(store);
    const { addSelectedPeopleToFavourites, addSelectedPeopleToList, clearSelectedPeople, getContactsLists } = store;

    const currentStep = ref('saveLocation'),
        selectedList = ref(null),
        dontClearSelection = ref(false),
        listName = ref(''),
        footerDown = ref(true),
        noOfPeopleSaved = ref(null);

    const route = useRoute();
    const router = useRouter();

    const addToFavourites = () => {
        listName.value = 'Favourites';
        noOfPeopleSaved.value = selectedPeople.length;
        addSelectedPeopleToFavourites().then(() => {
            currentStep.value = 'success';

            resetState(5000);
        });
    }

    const toggleFooter = () => {
        footerDown.value = !(footerDown.value);
    }

    const addToContactsList = () => {
        currentStep.value = 'chooseList';
    }

    const savePeopleToList = (listId) => {
        noOfPeopleSaved.value = selectedPeople.length;
        listName.value = contactsLists.value.find(list => list.id === listId).name;
        addSelectedPeopleToList(listId).then(() => {
            currentStep.value = 'success';

            resetState(5000);
        });
    }

    const resetState = (timeout) => {
        // if timeout is set, use it, otherwise, default to 0
        setTimeout(() => {
            clearSelectedPeople();
            currentStep.value = 'saveLocation';
            listName.value = '';
            footerDown.value = true;
            noOfPeopleSaved.value = null;
            dontClearSelection.value = false;
            selectedList.value = null;
        }, timeout || 0);
    }

    const newList = () => {
        dontClearSelection.value = true;
        router.push({ name: 'contacts-list-create', query: { adding: 'true' } });
    }

    onMounted(() => {
        getContactsLists();
    });

    onUnmounted(() => {
        if (!(dontClearSelection.value)) {
            clearSelectedPeople();
        }
    });
</script>

<template>
    <transition name="slide-up">
        <div
            v-if="selectedPeople.length"
            class="fixed gradient bottom-0 left-0 w-full p-4 transition-all pb-28"
        >
            <div class="flex justify-between w-full px-2">
                <div class="inline-flex items-center text-white text-2xl pl-2">
                    <i class="fa-solid fa-users mr-4"></i>
                    <p class="text-white text-left my-0">{{ selectedPeople.length }}</p>
                </div>
                <div class="flex w-full justify-end space-x-4">
                    <template v-if="currentStep === 'saveLocation'">
                        <button
                            v-if="route.name !== 'favourites'"
                            type="button"
                            class="flex flex-col items-center text-center w-16"
                            @click="addToFavourites"
                        >
                            <i class="fa-solid fa-heart-circle-plus text-white text-2xl"></i>
                            <span class="text-xs text-white">Add to favourites</span>
                        </button>
                        <button
                            type="button"
                            class="flex flex-col items-center text-center w-16"
                            @click="addToContactsList"
                        >
                            <i class="fa-solid fa-user-plus text-white text-2xl ml-1.5"></i>
                            <span class="text-xs text-white">Add to contacts list</span>
                        </button>
                    </template>
                    <button
                        type="button"
                        class="flex flex-col items-center text-center w-16"
                        @click="resetState"
                    >
                        <i class="fa-solid fa-user-xmark text-white text-2xl ml-1.5"></i>
                        <span class="text-xs text-white">Clear Selection</span>
                    </button>
                </div>
            </div>

            <div
                v-if="currentStep === 'chooseList'"
                class="flex flex-col justify-center text-center w-full space-y-4 mt-4"
            >
                <p v-if="contactsLists.length" class="text-white text-lg font-medium my-0">Select a contact list to add the selected people to</p>
                <p class="text-white text-lg font-medium" v-else>You don't appear to have any contacts lists.</p>

                <select class="w-full bg-white text-black p-2 rounded-md" v-model="selectedList" v-if="contactsLists.length">
                    <option v-for="list in contactsLists" :key="list.id" :value="list.id">{{ list.name }}</option>
                </select>

                <ns-button @click="savePeopleToList(selectedList)" label="Add to list" :disabled="!selectedList" v-if="contactsLists.length" />
                <ns-button @click="newList" label="Create new list" outline colour="white text-white" />
            </div>

            <div
                v-else-if="currentStep === 'success'"
                class="flex flex-col justify-center text-center w-full space-y-4"
            >
                <p class="text-white text-lg font-medium">{{ selectedPeople.length }} {{ selectedPeople.length > 1 ? ' people have ' : ' person has ' }} been added to {{ listName }}</p>
            </div>
        </div>
    </transition>
</template>

<style scoped>
    .slide-up-enter-active, .slide-up-leave-active {
        transition: transform 0.3s;
    }

    .slide-up-enter, .slide-up-leave-to {
        transform: translateY(100%);
    }

    .slide-up-enter-to, .slide-up-leave {
        transform: translateY(0);
    }

    .slide-up-enter-from {
        transform: translateY(100%);
    }
</style>
