<script setup>
import { useRoute } from "vue-router";
import {onMounted, onUnmounted, ref} from "vue";
import { useContactsLists, storeToRefs } from "@/stores/ContactsLists";

import Person from "@/components/people/Person.vue";
import PeopleSelected from "@/components/contacts-lists/PeopleSelected.vue";

const store = useContactsLists();
const { favouriteContacts } = storeToRefs(store);
const { getFavouriteContacts, selectedPeople } = store;

const isOnline = ref(navigator.onLine);

const updateOnlineStatus = () => {
    isOnline.value = navigator.onLine;
};

onMounted(() => {
    if (isOnline.value) {
        getFavouriteContacts();
    }
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
});

onUnmounted(() => {
    window.removeEventListener('online', updateOnlineStatus);
    window.removeEventListener('offline', updateOnlineStatus);
});
</script>

<template>
    <div>
        <h1>My Favourites</h1>

        <div class="flex flex-col space-y-4" v-if="favouriteContacts?.people_entries?.length">
            <person
                v-for="person in favouriteContacts.people_entries"
                :key="person.id"
                :person="person"
                compact
                :show-checkbox="isOnline"
            />
        </div>

        <div class="flex flex-col space-y-2" v-else>
            <p class="text-ns-deep-blue py-8">
                You don’t appear to have any favourite contacts saved.
            </p>

            <p class="text-ns-deep-blue">
                Click <router-link class="text-ns-dark-blue" :to="{name: 'people-search'}">here</router-link> to go to our people and favourite a contact.
            </p>
        </div>

        <people-selected />
    </div>
</template>
