<script setup>
    import PeopleListing from '@/components/algolia/people/Listing.vue';
    import PeopleSelected from "@/components/contacts-lists/PeopleSelected.vue";
    import OfflineListing from "@/components/people/OfflineListing.vue";
    import { useAuthStore, storeToRefs } from "@/stores/Auth";
    import { useContactsLists } from "@/stores/ContactsLists";
    import {computed, onMounted, onUnmounted, ref} from "vue";

    const authStore = useAuthStore();
    const { createMainSiteAccount } = authStore;
    const { user, userHasMainSiteAccount } = storeToRefs(authStore);

    const contactsListsStore = useContactsLists();
    const { selectedPeople } = storeToRefs(contactsListsStore);

    const loggedIn = computed(() => !!user.value);

    const
        isOnline = ref(navigator.onLine),
        currentPeople = ref([]);

    const updateOnlineStatus = () => {
        isOnline.value = navigator.onLine;
    };

    onMounted(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    onUnmounted(() => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
    });

    const itemsUpdated = (items) => {
        currentPeople.value = items.map(i => i.id);
    }

    const selectAll = () => {
        if (!currentPageSelected.value) {
            selectedPeople.value = [
                ...selectedPeople.value,
                ...currentPeople.value
            ];
        } else {
            selectedPeople.value = selectedPeople.value.filter(p => !currentPeople.value.includes(p));
        }
    }


    const deselectAllPages = () => {
        selectedPeople.value = [];
    }

    const currentPageSelected = computed(() => {
        return currentPeople.value.every(p => selectedPeople.value.includes(p));
    });

    const itemsSelectedOnOtherPage = computed(() => {
        return selectedPeople.value.some(p => !currentPeople.value.includes(p));
    });
</script>
<template>
    <div
        class="flex flex-col space-y-4"
        :class="{
            'pb-24': selectedPeople.length > 0
        }"
    >
        <h1>People Search</h1>

        <div class="offline-banner bg-yellow-100 text-yellow-800 p-4 rounded mb-4" v-if="!isOnline">
            <p>
                Please note, as you are currently offline, your results may only reflect information from the last day you were connected. Once you are back online, the app will automatically and seamlessly resync so you have the very latest information.
            </p>
            <p>
                If you're unsure about the most up-to-date version, see our website's people search at <a href="https://north-standard.com/our-people" class="underline underline-offset-4">https://north-standard.com/our-people</a>.
            </p>
        </div>

        <div v-if="isOnline">
            <people-listing
                search-index="people_listing_title_asc"
                :logged-in="loggedIn"
                @items-updated="itemsUpdated"
                :filters="[
                    {
                        key: 'people_function.title',
                        label: 'Function'
                    },
                    {
                        key: 'people_sector.title',
                        label: 'Sector'
                    },
                    {
                        key: 'office.title',
                        label: 'Office'
                    },
                    {
                        key: 'people_leadership_group.title',
                        label: 'Leadership'
                    }
                ]"
            >
                <template #config>
                    <ais-configure
                        filters="status:published AND site:default"
                    ></ais-configure>
                </template>

                <template #actions>
                    <div class="flex space-x-0 flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2" v-if="loggedIn">
                        <button
                            @click="selectAll"
                            class="rounded-md text-ns-deep-blue px-12 font-semibold py-2 bg-ns-modern-green hover:opacity-80 disabled:cursor-not-allowed disabled:bg-opacity-40"
                        >
                                <span v-if="currentPageSelected">
                                    Deselect All
                                    <span v-if="itemsSelectedOnOtherPage"> (This page)</span>
                                </span>
                            <span v-else>Select All</span>
                        </button>

                        <button
                            @click="deselectAllPages"
                            v-if="itemsSelectedOnOtherPage"
                            class="rounded-md bg-transparent text-ns-deep-blue px-6 font-semibold py-2 border border-ns-deep-blue hover:bg-ns-deep-blue hover:text-white"
                        >
                            Deselect All (All pages)
                        </button>
                    </div>
                </template>
            </people-listing>
        </div>

        <div v-else>
            <offline-listing />
        </div>

        <people-selected v-if="isOnline" />
    </div>
</template>

<style scoped>
.offline-banner {
    border: 1px solid #ecc94b;
}
</style>
